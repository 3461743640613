import './RoleCarousel.css'
import { Container, Row, Col} from 'react-bootstrap'
import { RoleSelector } from './RoleSelector'

function ChooseRole(props) {
    //comment
    return (
        <Container id="ChooseYourRole" className='mt-5 pt-4'>
            <Row>
                <Col>
                <div className='sectionHeading mt-0 mb-5' align="left">
                    <h1>1. CHOOSE YOUR ROLE</h1>
                    <p>Take a look at the different roles within the NeoVerse</p>
                </div>
                </Col>
            </Row>
            <Row>
                <RoleSelector />
            </Row>
        </Container>
    )

}

export default ChooseRole