import { Card, Col, Container, Row } from "react-bootstrap";
import { NeoChampsTeamCard } from "./NeoChampsTeamCard";
import { teamAmountsAtom, teamSelectionAtom } from "../../atomfile";
import { useRecoilState } from "recoil"
import { scripts } from "neo-flow-contracts";
import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";
import { useEffect, useRef, useState } from "react";
import GraffleSDK from "../../functions/Graffle";


// function amountLeftInTeam(teamAmounts, team_data) {
//     var amountsInTeam = { "Race Director": 1, "Crew Chief": 0, "Spectator": 0, "Pit Crew": 0 }

//     Object.keys(teamAmounts).map((i, index) => {
//         const team_details = teamAmounts[i]
//         const baseline_team_details = team_data[i]

//         Object.keys(team_details).map((item, index) => {
//             //     console.log("team data: " + baseline_team_details[item])
//             // console.log("item: " + item + team_details[item])
//             amountsInTeam[item] = baseline_team_details[item] - team_details[item].length
//             // console.log(amountsInTeam)
//         }
//         )

//     }
//     )

//     return amountsInTeam

// }

// function updateTeamAmountsDict(dict, teamId, avatarId, role) {
//     console.log(dict[teamId])
//     let copy_dict = {...dict}; //create a new copy
//     var arr_ = [...copy_dict[teamId][role]];
//     var index = copy_dict[teamId][role].indexOf(avatarId);
//     arr_.splice(index, 1);
//     copy_dict[teamId][role] = arr_

//     // console.log("new_dict")
//     // console.log(new_dict)

//     // console.log("new_dict[teamId][role]")
//     // console.log(new_dict[teamId][role])
    
//     return copy_dict
// }

function checkFull(teams, roleAmounts) {
    var new_RoleAmounts = roleAmounts   

    // do a check on all teams to see which are full
    for (var i = 0; i < teams.length; i++) {
        if(roleAmounts[teams[i]] !== undefined){
            roleAmounts[teams[i]] = roleAmounts[teams[i]]
        }
        else {
            roleAmounts[teams[i]] = {"Spectator" : 0, "Pit Crew" : 0, "Crew Chief" : 0, "Race Director" : 0}
        }
    }

    return new_RoleAmounts
}

export function NeoChampsTeams() {
    const teams = [1,2,3,4,5,6,7,8,9,10]
    const [trigger, setTrigger] = useState(false);
    const [teamAmounts, setTeamAmounts] = useState(null)
    const [teamAmountsForAtom, setTeamAmountsAtom] = useRecoilState(teamAmountsAtom)
    const [user, setUser] = useState({ loggedIn: null })
    const [roleAmounts, setRoleAmounts] = useState(null)

    var dict = {};

    async function getAmountsInTeams(address) {
        const response = await fcl.send([
            fcl.script(scripts.avatarsInSeries),
            fcl.args([fcl.arg(address, t.Address), fcl.arg("Preseason", t.String)]),])
        const tA = await fcl.decode(response);
        console.log("running script")
        setTrigger(true)
        setTeamAmounts(tA)
        // setTeamAmountsAtom(tA)
    }


    // useEffect(() => {
    //     console.log(JSON.stringify(latestMessage, null, 2))
    // }, [latestMessage]
    // )

    // let conn = useRef();
    // useEffect(() => {
    //     const streamSDK = new GraffleSDK();
    //     const feed = async (message) => {
    //         //message received, do something (need to filter)
    //         if (message.flowEventId === "A.73aec56c5663b5b0.NeoAvatar.Purchased") {
    //             // getAmountsInTeams("0x73aec56c5663b5b0")
    //             // setTeamAmountsAtom(teamAmounts)

    //             console.log(teamAmounts)
    //             Object.keys(teamAmounts).map((i, index) => {
    //                 const team_details = teamAmounts[i]
    //                 var role_values_dict = {}
    //                 Object.keys(team_details).map((item, index) => {
    //                     // console.log("team data: " + baseline_team_details[item])
    //                     // console.log("item: " + item + team_details[item].length)

    //                         role_values_dict[item] = team_details[item].length
    //                 }
    //                 )
    //                 dict[i] = role_values_dict

    //             }
    //             )
                
    //             setRoleAmounts(checkFull(teams, dict))

    //             // console.log("TEAM AMOUNTS")
    //             // console.log(teamAmounts)
    //         }
    //         if (latestMessage.flowEventId === "A.60fcd8cb556cf57f.Editioned.Deposited" && message.blockEventData.id === voucherIdentifier) {
    //             setLoadProgress(66)
    //         }
    //         if (latestMessage.flowEventId === "A.60fcd8cb556cf57f.Voucher.Consumed" && message.blockEventData.id === voucherIdentifier) {
    //             setLoadProgress(100)
    //         }


    //         setLatestMessage(message);
    //         console.log(message);

    //     };
    //     async function startConn() {
    //         //console.log("Creating the stream")
    //         conn.current = await streamSDK.stream(feed);
    //     }
    //     startConn()
    // }, []);
    // useEffect(() => () => {
    //     //console.log("Stopping the connection")
    //     conn.current.stop()
    // }, []);


    

    useEffect(() => fcl.currentUser().subscribe(setUser), [])


    const [teamState, setTeamState] = useRecoilState(teamSelectionAtom);

    useEffect(() => {
        getAmountsInTeams("0xb25138dbf45e5801")
    }, [])

    useEffect(() => {
        // getAmountsInTeams("0x73aec56c5663b5b0")
        // if (teamAmounts !== null) {
        //     console.log(teamAmounts)
        // }
        // else {
        //     console.log("getting amounts in teams")
        //     getAmountsInTeams(user.addr)
        // }

        // if (user.loggedIn !== null) {
            
            // console.log("The returned values are:")
            // console.log(teamAmounts)
            if (teamAmounts !== null) {
                // console.log(teamAmounts)
                Object.keys(teamAmounts).map((i, index) => {
                    const team_details = teamAmounts[i]
                    var role_values_dict = {}
                    Object.keys(team_details).map((item, index) => {
                        // console.log("team data: " + baseline_team_details[item])
                        // console.log("item: " + item + team_details[item].length)

                        role_values_dict[item] = team_details[item].length
                    }
                    )
                    dict[i] = role_values_dict

                }
                )   
                setRoleAmounts(checkFull(teams, dict))

            }

        }
        // // else {
        //     console.log("not logged in")
        // }
    // }
        , [teamAmounts])

    

    // console.log("NEW THING TRYING")
    // console.log(roleAmounts)
    // console.log(teamAmounts)
    setTeamAmountsAtom(teamAmounts)
    
    
    return (
        <Container>
            <Row className="w-100">
                <h1>2. CHOOSE YOUR TEAM</h1>
                <Col className="px-3 mt-3" sm="12" lg="auto">
                </Col>
            </Row>
            <Row>
                <Col className="px-3 m-3 py-0">
                    Select the team you want to be in
                </Col>
            </Row>
            <Row className="mt-3 mx-0 justify-content-center">
                {roleAmounts !== null &&

                    Object.keys(roleAmounts).map(key => (
                        <Col md={3} xs={12} className="pb-3">
                            <div onClick={() => setTeamState(key)}>
                                <NeoChampsTeamCard team={key} team_data={roleAmounts} team_selected={teamState} />
                            </div>
                        </Col>))}
            </Row>

        </Container>
    )
}