import { Container, Col, Row, Tabs, Tab } from "react-bootstrap";
import { TheRoles } from "./TheRoles";
import { NeoChampsTeams } from "../NeoChampsWarmUp/NeoChampsTeams";

export function TeamsRoles() {
    return (
        <Container id="TeamsRoles" className="pt-5 my-5 px-lg-0">
            <Row>
            <Col>
                <NeoChampsTeams />
            </Col>
          </Row>
        </Container>
    )
}