import { Container, Col, Row, Tabs, Tab } from "react-bootstrap";
import Spacer from "../Spacer";

export function NeoChampsTextBanner() {
    return (
        <Container className="w-100">
            <Row className="pt-5 m-0 mx-auto">
                <h1 className="mx-auto">Find the perfect role for you</h1>
            </Row>
            <Row className="d-none d-md-flex pb-5 m-0 pr-5 pl-5 ml-5 mr-5">
                <p align="center" className="mx-auto text-align-center">For the Neo Championship preseason, we have 10 different teams each with 14 spaces each. Each team will then have the opportunity to add 6 people later.</p>
            </Row>
            <Row className="pb-5 d-md-none">
                <p align="center" className="mx-auto text-align-center">For the Neo Championship preseason, we have 10 different teams each with 14 spaces each. Each team will then have the opportunity to add 6 people later.</p>
            </Row>
            <Row className="pb-5">
                <p align="center" className="mx-auto text-align-center"><b>Mint will start on the 18th February 2022</b></p>
            </Row>
            <Spacer/>
        </Container>
    );
}