import { Container, Row, Col, Carousel, Image, Breadcrumb, Button } from 'react-bootstrap'
import FinaliseSelectionCard from './FinaliseSelectionCard'
import { useRecoilValue } from "recoil";
import { roleSelectionAtom } from "../../atomfile";
import { teamSelectionAtom } from "../../atomfile";


function FinaliseSelection(props) {

    
    const role_data = useRecoilValue(roleSelectionAtom)
    const team_data = useRecoilValue(teamSelectionAtom)

    return (

        
        <Container className='mt-5 pt-4'>
            <Row>
                <Col>
                <div className='sectionHeading mt-0 mb-5' align="left">
                    <h1>3. YOUR SELECTION</h1>
                    <p>Check over your selection, then all that's left to do is mint.</p>
                </div>
                </Col>
            </Row>
            <Row>
                <Col className="mx-auto">
                    <FinaliseSelectionCard role={role_data} team={team_data}/>
                </Col>
            </Row>
        </Container>
    )

}

export default FinaliseSelection