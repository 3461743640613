import { Row, Col, Image, Container } from "react-bootstrap";
import { RoleSelectorItem } from "./RoleSelectorItem";
import { roleSelectionAtom } from "../../atomfile"
import { useRecoilState } from "recoil"


export function RoleSelector() {

    // const [roleState, setRoleState] = useState({
    //     spectator: false,
    //     pitcrew: false,
    //     crewchief: false,
    //     racedirector: false
    // });

    const [roleState, setRoleState] = useRecoilState(roleSelectionAtom);

    return (
        <Container>
            <Row>
                <Col xs={6} md={3}>
                <div onClick={() => setRoleState({"Spectator": true, "Pit Crew": false, "Crew Chief":false, "Race Director": false})}>
                    <RoleSelectorItem role="Spectator" active={roleState["Spectator"]}/>
                </div>
                </Col>
                <Col xs={6} md={3}>
                <div onClick={() => setRoleState({"Spectator": false, "Pit Crew": true, "Crew Chief":false, "Race Director": false})}>
                    <RoleSelectorItem role="Pit Crew" active={roleState["Pit Crew"]}/>
                </div>
                </Col>
                <Col xs={6} md={3}>
                <div onClick={() => setRoleState({"Spectator": false, "Pit Crew": false, "Crew Chief":true, "Race Director": false})}>
                    <RoleSelectorItem role="Crew Chief" active={roleState["Crew Chief"]}/>
                </div>
                </Col>
                <Col xs={6} md={3}>
                <div onClick={() => setRoleState({"Spectator": false, "Pit Crew": false, "Crew Chief":false, "Race Director": true})}>
                    <RoleSelectorItem role="Race Director" active={roleState["Race Director"]}/>
                </div>
                </Col>
            </Row>

        </Container>

    )
}