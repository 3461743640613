import { Col, Image, Row, Button } from 'react-bootstrap'
import "./NeoChampsWarmUp.css"

export function RoleSelectorItem({ role, active }) {
    const image_map = {
        "Spectator": "/pages/images/neochampwarmup/spectator-select.png",
        "Pit Crew": "/pages/images/neochampwarmup/pit-crew-select.png",
        "Crew Chief": "/pages/images/neochampwarmup/crew-chief-select.png",
        "Race Director": "/pages/images/neochampwarmup/race-director-select.png"
    }

    const text_map = {
        "Spectator": ["Spectator", "13 per team"],
        "Pit Crew": ["Pit Crew", "5 per team"],
        "Crew Chief": ["Crew Chief", "2 per team"],
        "Race Director": ["Race Director", "1 per team"]
    }
    // console.log(role)
    var itemClassName = "RoleSelectorItem"

    if (active) {
        itemClassName = "RoleSelectorItemActive"
    }
    return (
        <div id={itemClassName}>
            <Row>
                <Image className="mx-auto p-1" src={image_map[role]} fluid>
                </Image>
            </Row>
            <Row className='pt-2'>
                <p className='mx-auto'>{text_map[role][0]}</p>
            </Row>
            <Row className='pt-1'>
                <p className='mx-auto'>{text_map[role][1]}</p>
            </Row>

        </div>
    )
}