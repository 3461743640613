import { Container, Row, Col, Button, Image } from "react-bootstrap";
import './FindOutMore.css'
import pitcrew from '../../public/pages/images/home/pit-crew-banner.png'

function FindOutMore() {
    return (
        <Container className="p-lg-0">
            <div className='d-block my-5 p p-3 FindOutMore'>
                <Row>
                    <Col md="auto" lg='6' className="m-5">
                        <div className="TitleText">Welcome to the</div>
                        <div className="NeoText my-3">NEO ONE</div>
                        <p>What’s that? A real motorcyle as one of the Founder NFT Benefits? That’s right, Neo One is an all electric motorcyle with the latest tech packed into a powerful motor. </p>
                        <Button variant="dark" style={{backgroundColor: "#DDE32C", borderColor: "#DDE32C", color: "#000"}} className="mt-4">Find out more</Button>
                    </Col>
                    <Col className='d-flex justify-content-center'>
                        <Image className='pt-3 pt-3' src='/pages/images/neo-bike-side.webp' fluid/>
                    </Col>
                </Row>
            </div>

            {/* <div className='d-lg-none mt-5 mb-5 p p-3' style={{ height: 'auto', backgroundColor: "#FFD8C5", margin: 'auto', borderRadius: "16px" }}>
                <Row className='mx-auto'>
                        <Col md="auto" xs='12'style={{ paddingTop: '50px' }}>
                            <h1>How does it work?</h1>
                            <p>Minting an NFT is a game of luck. To make it fair, each mint will give you a random chance of getting any role from spectator to race director all for the same price, meaning anyone could get anything, but some will be extra lucky! </p>
                            <Button variant="dark" style={{width: "100%"}}>Find out more</Button>
                        </Col>
                        <Col className='d-flex justify-content-center'>
                            <Image style={{maxHeight:'100%', maxWidth:'100%'}} className='pt-3' src={pitcrew}/>
                        </Col>
                    </Row>
            </div> */}

        </Container>
    )
}
export default FindOutMore