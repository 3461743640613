import { atom } from 'react-atomic-state';
import { Container, Row, Col, Carousel, Image, Breadcrumb, Button } from 'react-bootstrap'
import * as fcl from "@onflow/fcl";
import { useEffect, useRef, useState } from "react";
import GraffleSDK from "../../functions/Graffle";
import * as t from "@onflow/types";
import { transactions } from 'neo-flow-contracts'
import { Tx } from "../../functions/transaction";
import { useRecoilState, useRecoilValue } from 'recoil';
import { teamAmountsAtom } from '../../atomfile';

export function activeCheck(roleStates) {
    for(var key in roleStates) {
        if(roleStates[key]) {
            return key
        }
    }
}


function selectId(team, role, dict) {
    
    try {
        // console.log(dict[0][team][role][0])
    return parseInt(dict[0][team][role][0])

    }
    catch(e) {
        console.log("Role is not available")
        
        return 0    
    }
    
}

function FinaliseSelectionCard(props) {
    var id = null
    const team_amounts = useRecoilState(teamAmountsAtom)
    const [user, setUser] = useState();
    useEffect(() => fcl.currentUser().subscribe(setUser), [])
    const image_map = {
        "Spectator":"/pages/images/neochampwarmup/spectator-select.png",
        "Pit Crew" : "/pages/images/neochampwarmup/pit-crew-select.png",
        "Crew Chief" : "/pages/images/neochampwarmup/crew-chief-select.png",
        "Race Director" : "/pages/images/neochampwarmup/race-director-select.png"
    }

    const handlePurchaseAvatar = async (amount, id) => {
        try {
          await Tx(
            [
              fcl.transaction(transactions.purchaseNeoAvatar),
              fcl.args([
                fcl.arg(id, t.UInt64),
                fcl.arg(amount, t.UFix64)
              ]),
              fcl.proposer(fcl.currentUser().authorization),
              fcl.payer(fcl.currentUser().authorization),
              fcl.authorizations([fcl.currentUser().authorization]),
              fcl.limit(9999),
            ],
            {
              onStart() {
                console.log("start")
      
              },
              onSubmission() {
                console.log("submitted")
      
              },
              async onSuccess(status) {
                document.getElementById('success-nft-purchase').classList.remove("d-none")
              },
              async onError(error) {
                if (error) {
                  const { message } = error;
                  console.log(message)
                }
              },
            }
          );
        } catch (e) {
          console.log(e);
        }
      }

    const role_data = props.role
    const team_data = props.team

    const role_chosen = activeCheck(role_data)

    var text = "No Role or Team Chosen"

    // console.log(team_data)

    if(typeof role_chosen === 'undefined' && team_data === null)
    {
        text = "No Role or Team Chosen"
    }
    else if(typeof role_chosen !== 'undefined' && team_data === null)
    {
        text = role_chosen + " - " + "No Team Chosen"
    }
    else
    {
        text = role_chosen + " - Team #" + team_data

        // console.log(team_amounts)

        if(team_amounts !== null) {
            // console.log(team_amounts)
            // console.log(team_data)
            // console.log(role_data)
            id = selectId(team_data, role_chosen, team_amounts)
            // console.log(id)
        }
    }

    return (
        <div>
            {(typeof role_chosen !== 'undefined') && 
            <Row className="mb-3" id="RoleSelectorItemActive">
                <Image className="mx-auto p-1" src={image_map[role_chosen]} fluid>
                </Image>
            </Row>
}
            <Row>
                <h1 className="mx-auto">{text}</h1>
            </Row>
            <Row className="mb-2">
                <p className='mx-auto'>Price: <span style={{fontWeight:"700"}}>1 FLOW</span></p>
            </Row>
            <Row className="mb-5 justify-content-center">

            {
                (typeof role_chosen !== 'undefined' && team_data === null) &&
                <p style={{color:'red'}} className='mx-auto'>You must select a team before minting.</p>
            }
            {
                (typeof role_chosen === 'undefined' && team_data === null) &&
                <p style={{color:'red'}} className='mx-auto'>You must select a role and a team before minting.</p>
            }
            {
                (typeof role_chosen !== 'undefined' && team_data !== null) &&
                <Row>
                    <Col align="center" xs={12}>
                    <Button align="center" className="m-0" onClick={() => handlePurchaseAvatar("1.00", id)} variant="dark">Mint</Button>
                    </Col>
                    <Col xs={12}>
                    <div id="success-nft-purchase" className='d-none'><p align="center">View your {role_chosen} for Team #{team_data} - <a target="_blank" href={user ? 'https://find.xyz/' + user.addr : 'https://find.xyz/'}>View on find</a></p></div>
                    </Col>
                </Row>
            }
            
            </Row>
            
        </div>
    )

}

export default FinaliseSelectionCard