import { React } from "react";
import RoleCarousel from "../components/Home/RoleCarousel";
import FindOutMore from "../components/Home/FindOutMore";
import { Container } from "react-bootstrap";
import { BuildRacingTeams } from "../components/Home/BuildRacingTeams";
import { HeaderHome } from "../components/Home/HeaderHome";
import '../components/Home/home.css'
import { TeamsRoles } from "../components/Home/TeamsRoles";
import Spacer from "../components/Spacer";

function Home() {

  return (
    <Container fluid className="g-0 p-0">
      <HeaderHome />
      <BuildRacingTeams />
      <RoleCarousel />
      <TeamsRoles />
      <Spacer />
      <FindOutMore />
      
    </Container>
  );
}

export default Home