import { React } from "react";
import ChooseRole from "../components/NeoChampsWarmUp/ChooseRole";
import FindOutMore from "../components/NeoChampsWarmUp/FindOutMore";
import { Container } from "react-bootstrap";
import { HeaderNeoChampsWarmUp } from "../components/NeoChampsWarmUp/HeaderNeoChampsWarmUp";
import '../components/Home/home.css'
import { TeamsRoles } from "../components/NeoChampsWarmUp/TeamsRoles";
import Spacer from "../components/Spacer";
import { NeoChampsTextBanner } from "../components/NeoChampsWarmUp/NeoChampsTextBanner";
import FinaliseSelection from "../components/NeoChampsWarmUp/FinaliseSelection";
import {RecoilRoot} from "recoil"
function Home() {

    return (
        <Container fluid className="g-0 p-0">
            <HeaderNeoChampsWarmUp />
            <RecoilRoot>
                <NeoChampsTextBanner />
                <ChooseRole />
                <TeamsRoles />
                <FinaliseSelection />
            </RecoilRoot>
            <Spacer />
            {/* <FindOutMore /> */}

        </Container>
    );
}

export default Home