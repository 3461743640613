import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export function HeaderNeoChampsWarmUp() {
    return (
        <Container fluid id="header" className="HeaderHome">
        <Container>
          <Row>
            <Col className="p-3 mt-5">
              <div className="w-100 h-100">
              <div className="HeaderCardBg mx-auto mb-5 p-3 pb-5">
                <Row>
                  <Col className="mt-4">
                    <h1 className="text-center">WELCOME TO THE NEO CHAMPIONSHIP</h1>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <h1 className='mask-text'className='mask-text' style={{fontSize: '70px', lineHeight: '70px'}}>PRE SEASON</h1>
                  </Col>
                </Row>
                <Row>
                  <Col className="m-2 mx-5 px-5 text-center d-md-block d-none">
                    <p>Miss our first pre-launch championships? Enter our second warm up qualifiers, to get a hint of what’s to come</p>
                  </Col>
                  <Col className="m-4 px-4 text-center d-sm-block d-md-none">
                    <p className="small">Miss our first pre-launch championships? Enter our second warm up qualifiers, to get a hint of what’s to come</p>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <div style={{zIndex: "1"}}><Button variant="dark" className="pt-2" style={{zIndex: "1", textDecoration:"none"}} href="#ChooseYourRole">Choose your role</Button></div>
                  </Col>
                </Row>
                <div className="HeaderBikers d-md-block d-none w-100 mx-auto" style={{zIndex: "0"}}>
                 <Image src="/pages/images/home/header-bikers.webp" fluid />
              </div>
              <div className="HeaderBikersSm d-xs-block d-md-none d-flex mx-auto">
                 <Image src="/pages/images/home/header-bikers-sm.webp" fluid />
              </div>
                </div>
              
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    )
}