import HeaderReveal from "../components/RevealStandard/HeaderReveal"
import RoleNextSteps from "../components/RevealStandard/RoleNextSteps"

function RevealStandard(props) {
    return (  
            <div>
                <HeaderReveal role={props.role}/>
                <RoleNextSteps />
            </div>
        )
           
}

export default RevealStandard 