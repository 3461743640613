import { Container, Row, Col, Button, Image, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import discordimage from '../../public/pages/images/discordimage.png'
import arrow from '../../public/pages/images/arrow.png'
import './Footer.css'

function Footer() {
    return (
        <Container className="">
            <div className='d-none d-lg-block mt-5 mb-5' style={{ height: 'auto', backgroundColor: "#F2F4B0", margin: 'auto', borderRadius: "16px" }}>
                <Row className="mt-5">
                    <Col md="auto" style={{ paddingTop: '50px' }}>
                        <h1 class="ml-5 pt-0">BECOME A MEMBER OF OUR</h1>
                        <h1 class="mask-text ml-5" style={{ fontSize: "56px" }}>GROWING COMMUNITY</h1>
                        <Button variant="dark" style={{ marginLeft: '48px' }} >Join the community</Button>
                    </Col>
                    <Col style={{ textAlign: 'left', position: 'relative' }}>
                        <Image style={{ position: 'absolute', bottom: '0' }} src={arrow} rounded />
                    </Col>
                    <Col className='text-end'>
                        <Image src={discordimage} rounded />
                    </Col>
                </Row>
            </div>

            <div className='d-lg-none mt-5' style={{ height: 'auto', backgroundColor: "#F2F4B0", margin: 'auto', borderRadius: "16px" }}>
                <Col className='p-3'>
                    <Row className='pl-4 pt-4'>
                        <h1 align="left" style={{ lineHeight: '50px' }}>BECOME A MEMBER OF OUR</h1>
                    </Row>
                    <Row className='pl-4'>
                        <h1 className='mask-text' style={{ fontSize: '48px', lineHeight: '48px' }}>GROWING COMMUNITY</h1>
                    </Row>
                    <Row className="mb-4">
                        <Button href="https://discord.gg/NeoNFT" variant="dark" style={{ width: '95%', lineHeight: "30px" }} className="mx-auto">Join the community</Button>
                    </Row>
                </Col>
                <Image style={{ width: '100%', borderRadius: '16px' }} src='/pages/images/mint/banner-image-mobile.png' />
            </div>
            <Container id="FooterLinks" className="p-lg-0 w-100 pt-4 mt-3">
                <Row>
                    <Col >
                    <Row>
                    <h1 align="center" className="built-on-flow mx-auto text-align-center"> Proudly built on<span><Image src="/pages/images/flow-logo.png"></Image></span></h1>
                    </Row>
                    
                    </Col>
                </Row>
                <Row className="my-5">
                    <Col align="center"><Image src="/pages/images/neo-collectibles-logo.webp" /></Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Nav className="mx-auto my-3">
                            <Nav.Link as={Link} to={"/"}>Home</Nav.Link>
                            {/* <Nav.Link as={Link} to={"/explore"}>Explore</Nav.Link> */}
                            {/* <Nav.Link as={Link} to={"/neo-fest"}>NeoFest</Nav.Link> */}
                        </Nav>
                    </Col>
                </Row>
                <Row className="mb-3 mt-5 p-3 p-lg-0">
                    <Col>
                        <div className="footerCopyrightText">© Neo Motorcycles 2022 Rights Reserved</div>
                    </Col>
                    <Col align="right">
                        <a href="https://discord.gg/neonft" target="_blank" className="m-2"><Image src="/pages/images/socials/discord.png" width="24px" height="24px" /></a>
                        <a href="https://www.instagram.com/neo.collectibles/" target="_blank" className="m-2"><Image src="/pages/images/socials/instagram.png" width="24px" height="24px" /></a>
                        <a href="https://twitter.com/neocollectibles" target="_blank" className="m-2"><Image src="/pages/images/socials/twitter.png" width="24px" height="24px" /></a>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
export default Footer