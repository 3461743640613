import { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { roleSelectionAtom, teamSelectionAtom } from "../../atomfile";
import "./NeoChampsWarmUp.css"
import { useRecoilValue, useRecoilState } from "recoil";
import { activeCheck } from "./FinaliseSelectionCard";

export function NeoChampsTeamCard(props) {
    const card_data = props.team_data
    var available = false;
    var text_colour = "black";

    const role_max = {
        "Race Director": 1, "Crew Chief": 2, "Spectator": 13, "Pit Crew": 5
    }

    const role_colours = {
        "Race Director": "#B74AC9",
        "Crew Chief": "#DA932A",
        "Pit Crew": "#1467B4",
        "Spectator": "#119235"

    }
    const [teamState, setTeamState] = useRecoilState(teamSelectionAtom);

    const role = activeCheck(useRecoilValue(roleSelectionAtom))

    var itemClassName = "pt-2 pb-2 px-4 NeoChampsTeamCard"
    try {
        // console.log(props.team_selected)
        // console.log(props.team)
        if (role_max[role] - card_data[props.team][role] < role_max[role]) {
            available = true
            text_colour = role_colours[role]

            if (props.team_selected === props.team) {
                // console.log(props.team_selected)
                // console.log(props.team)
                itemClassName = "pt-2 px-4 pb-2 NeoChampsTeamCardActive"
            }
        }
        else {
            available = false
            itemClassName = "pt-2 px-4 pb-2 NeoChampsTeamCardDisabled"
            if (props.team_selected === props.team) {
                setTeamState(null)
            }

        }
    }
    catch (e) {
        available = false;
        console.log(props.team_selected)
        console.log(props.team)
        console.log(itemClassName)
    }

    return (
        <div>

            {available &&
                <Card id={itemClassName} className={itemClassName}>
                    <div className="TeamHeader">Team #{props.team}</div>
                    <p style={{ color: text_colour }}>{role} is available</p>
                    {Object.keys(card_data[props.team]).map(role => (
                        <div className="SmallText">{role} {role_max[role] - card_data[props.team][role]}/{role_max[role]}</div>))}

                </Card>
            }
            {!available &&
                <Card id={itemClassName} className={itemClassName}>
                    <div className="TeamHeader">Team #{props.team}</div>

                    <p>Role not available</p>

                    {Object.keys(card_data[props.team]).map(role => (
                        <div className="SmallText">{role} {role_max[role] - card_data[props.team][role]}/{role_max[role]}</div>))}

                </Card>
            }
        </div>
    )


}