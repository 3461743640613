import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import NavHead from "./components/NavHead/NavHead";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home"
import StartCollection from './pages/StartCollection';
import "./config";
import Profile from './pages/Profile';
import ScrollToTop from './functions/ScrollToTop';
import RevealStandard from './pages/RevealStandard';
import RevealFounder from './pages/RevealFounder';
import NeoFest from './components/NeoFest/NeoFest';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { CloseButton } from 'react-bootstrap';
import HowDoesItWork from './pages/HowDoesItWork';
import { TxTests } from './pages/txtests';
import { LoadingNft } from './components/LoadingNft/LoadingNft';
import NeoChampsWarmUp from './pages/NeoChampsWarmUp';

function App() {
  return (
    <Router>
      <div className="toastText">
        <Toaster toastOptions={{
          duration: Infinity,
          className: 'toastNotification'
        }}>
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <>
                  {icon}
                  {message}
                  {t.type !== 'loading' && (
                    <CloseButton onClick={() => toast.dismiss(t.id)}></CloseButton>
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster></div>
      <ScrollToTop />
      <NavHead />
      <Routes>
        {/* <Route path ='/' element={<Home  />} />
        <Route path ='/explore' element={<StartCollection />} />
        <Route path ='/profile' element={<Profile />} />
        <Route path ='/neo-fest' element={<NeoFest  />} />
        <Route path ='/reveal-standard' element={<RevealStandard  role='race director'/>} />
        <Route path ='/reveal-founder' element={<RevealFounder  />} />
        <Route path ='/how-does-it-work' element={<HowDoesItWork />} />
        <Route path ='/txtests' element={<TxTests />} />
        <Route path ='/loading' element={<LoadingNft />} /> */}
        <Route path ='/' element={<NeoChampsWarmUp />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
