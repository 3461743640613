import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { Breadcrumbs } from "../../functions/Breadcrumbs";
import './NeoFest.css'
import whatisneofest from "../../public/pages/images/neofest/what-is-neo-fest.png"
import neofestactivities from "../../public/pages/images/neofest/neo-fest-activities.png"
import iphone from '../../public/pages/images/neofest/iphone.png'

export function NeoFest(props) {
    let bcprops = {
        title: "Neo Fest",
        link: "/neo-fest",
        hideBanner: "true"
    }
    return (
        <div>
            <Breadcrumbs props={bcprops} />
            <Container fluid id="neoFestHeader" className='neoFestHeader h-100'>
                <Container className='d-none d-md-block'>
                    <Row className='pl-5 pr-5'>
                        <Col className="m-5 p-4">
                            <div className="HeaderCardBg mx-auto p-5">
                                <Row>
                                    <Col>
                                        <h1 id='reveal-header-h1' className="text-center m-0 p-0">The Next Best Tech Festival</h1>
                                        <div className='holo-title text-center'>NEO FEST</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <p id='reveal-header-p' className="p-4 m-0">The next tech comic con? With the best in industry, from crypto, tech, motor vehicles and more? Yup, and entry is included with ALL NFTs</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <Button variant="dark" className="card-button" >Mint a Role</Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className='d-md-none'>
                    <Container className='p-3 pr-3 pl-3 pb-5 pt-5'>
                        <div className="HeaderCardBg mx-auto p-1">
                            <Row className='d-flex justify-content-center' >
                                <Col className="p-3">
                                    <Row>
                                        <Col>
                                            <h1 id='reveal-header-h1' className="text-center m-0 p-0">The Next Best Tech Festival</h1>
                                            <div className='holo-title text-center' style={{ fontSize: '64px' }}>NEO FEST</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="d-flex justify-content-center">
                                            <p id='reveal-header-p' className="p-4 m-0">The next tech comic con? With the best in industry, from crypto, tech, motor vehicles and more? Yup, and entry is included with ALL NFTs</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="d-flex justify-content-center">
                                            <Button variant="dark" className="card-button" >Mint a Role</Button>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Container>
            </Container>
            <Container id="WhatIsNeoFest" fluid={true} style={{background: "linear-gradient(194.01deg, #D9E8EF 9.6%, #EAF2F5 89.64%)"}} className="py-5">
                <Container>
                <Row>
                    <Col xs='12' lg='6' className="pl-lg-3 pt-5 pr-5 pb-5 order-2 order-lg-1">
                        <h1 className="m-0">WHAT IS NEO FEST?</h1>
                        <p className="">Neo-Fest is a huge multi-day event. It will physically combine Motorcycles, Tech, NFTs and most importantly the phenomenal Neo Community</p>
                        <p className="">It will be hosted at a unique location to give all of our Neo Members a truly unforgettable experience</p>
                    </Col>
                    <Col xs='12' lg='6' align="right" style={{ position: "relative", top: "-0px" }} className="order-1 order-lg-2 p-0">
                        {/* <Image className='img-fluid justify-content-center pb-5 pt-5' src={whatisneofest} /> */}
                    </Col>
                </Row>
                </Container>
            </Container>
            <Container fluid id="EntryAndBudget">
                <Container style={{ backgroundColor: "#BCD7F0", borderRadius: "16px" }} className="my-5">
                    <Row>
                        <Col xs='12' lg='6' className="pl-lg-5 pt-5 pr-5 pb-5 order-2 order-lg-1" >
                            <h1 className='m-0 ml-5' style={{ textAlign: 'left' }}>ENTRY AND BUDGET</h1>
                            <p className='pb-3 ml-5' style={{ textAlign: 'left', fontFamily: 'inter', fontSize: '16px' }}>
                                This event will be exclusively accessible by Neo Members and cost you absolutely nothing! We want everyone to be able to meet up with the incredible people in their team and all the members of the Neo Community
                            </p>
                            <p className="ml-5">
                                The budget for this event is ridiculous and it will be a truly can’t-miss event
                            </p>
                        </Col>
                        <Col xs='12' lg='6' align="right" style={{ position: "relative", top: "-0px" }} className="order-1 order-lg-2 p-0">
                            <Image src='/pages/images/neofest/entry-lanyard.webp' fluid style={{ position: "relative", maxHeight: "430px" }} />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container id="NeoFestActivites">
                <Row className='pt-5 pb-5'>
                    <Col md='6' className='my-auto'>
                        <h1>NEO FEST ACTIVITIES</h1>
                        <p>Expect some of the most exciting and exclusive activities available at Neo-Fest</p>
                        <p>We will ensure that there are so many unique things to do at Neo-Fest that it’s almost impossible to get round all of them</p>
                        <p>We can’t give away any details just yet but we know you will leave wanting to come back for more every single year! </p>
                    </Col>

                    <Col md='6' className='my-auto'>
                        <Image className='img-fluid pt-5 pb-5 ' src={neofestactivities} />
                    </Col>
                </Row>
            </Container>
            <Container id="NeoCommunity">
                <div className='mt-5 mb-5 FindOutMore' style={{backgroundColor: "#FFD8C4"}}>
                    <Row>
                        <Col xs='12' md="auto" lg='6' className="m-5">
                            <h1>NEO COMMUNITY</h1>
                            <p>Alongside the activities at Neo-Fest we also want to make sure that you have the opportunity to meet your team and other Neo members from our community </p>
                            <p>We will provide spaces to discuss plans, projects and the next big move in a collaborative and inclusive way so that every Neo-Fest is a chance to start something incredible </p>
                        </Col>
                        <Col className='d-flex justify-content-center'>
                            <Image className='pt-3' src={iphone} />
                        </Col>
                    </Row>
                </div>
                {/* <div className='d-lg-none mt-5 mb-5' style={{ height: 'auto', backgroundColor: "#FFD8C4", margin: 'auto', borderRadius: "16px" }}>
                    <Row className='mx-auto'>
                        <Col md="auto" xs='12' style={{ paddingTop: '50px' }}>
                            <h1>How does it work?</h1>
                            <p>Minting an NFT is a game of luck. To make it fair, each mint will give you a random chance of getting any role from spectator to race director all for the same price, meaning anyone could get anything, but some will be extra lucky! </p>
                        </Col>
                        <Col className='d-flex justify-content-center p-0 m-0'>
                            <Image src={iphone} />
                        </Col>
                    </Row>
                </div> */}
                <Col className="d-block justify-content-center pt-5 pb-5">
                    <Row className='d-flex justify-content-center'>
                        <h1 style={{ fontSize: '45px', textAlign: 'center  ' }}>MORE DETAILS COMING SOON</h1>
                    </Row>
                    <Row className='d-flex justify-content-center'>
                        <Button variant="dark">Join the Discord</Button>
                    </Row>
                </Col>
            </Container>

        </div>
    )
}

export default NeoFest;