import { atom } from "recoil";

export const roleSelectionAtom = atom({
    key: "roleSelection",
    default: {
        "Spectators": false,
        "Pit Crew": false,
        "Crew Chief": false,
        "Race Director": false
    }
})

export const teamAmountsAtom = atom({
    key: "teamAmounts",
    default: null
})

export const teamSelectionAtom = atom({
    key: "teamSelection",
    default: null
})

export const mintableAtom = atom({
    key: "mintable",
    default: false
})
